.hours-bg {
    background-color: f0f4f8;
    font-family: 'Raleway', sans-serif;
    padding-top: 100px;
    padding-bottom: 289px;
  }
  
  .table-fixed {
    width: 40%;
  }
  
  /* Media Query for iPads */
  @media only screen and (max-width: 1024px) {
    .table-fixed {
      width: 80%;
    }
  }
  
  /* Media Query for Phones */
  @media only screen and (max-width: 480px) {
    .table-fixed {
      width: 100%;
    }
  }
  