/* Default styles */
.navbar {
  background-color: #001f3f;
  margin: 0;
}

.links {
  display: inline;
}

.links a {
  position: relative;
  color: white;
  transition: color 0.3s ease-in-out;
}

.links a::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: beige;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.links a:hover {
  color: beige;
}

.links a:hover::before {
  transform: scaleX(1);
}

.envios-logo {
  display: inline-block;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 10px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.admin-login-button,
.logout-button {
  --button-default-height: 50px;
  --button-default-font-size: 15px;
  --button-default-border-radius: 12px;
  --button-horizontal-padding: 35px;
  --button-raise-level: 5px;
  --button-hover-pressure: 3;
  --transform-speed: 0.175s;
  --button-primary-color: rgb(103, 10, 10);
  --button-primary-color-dark: beige;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: rgb(0, 0, 0);
  --button-primary-color-active: #000000;
  --button-primary-border: 6px solid transparent;
  --button-secondary-color: #f2f6f9;
  margin-right: 50px;
  margin-top: 10px;
}

.en-btn,
.es-btn {
  color: white;
}

.spanish {
  margin-top: 5px;
}

.menu {
  margin: auto;
  padding-right: 10px;
}

/* .toggle {
  margin-right: 40px;
} */

.translations {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

/* Media Queries */
/* Target iPhones and Android phones */
@media only screen and (max-device-width: 850px) {
  
  

  .toggle {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
}

/* Target tablets */
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .navbar {
    /* CSS rules specific to tablets */
    /* Example: Adjust the font size or background color */
    font-size: 18px;
    background-color: #333333;
  }
}

@media only screen and (max-device-width: 670px) {
  .toggle {
    display: flex;
    flex-direction: column;
    margin-right: auto;
  }

  @media only screen and (max-device-width: 820) {
.toggle {
  display: flex;
    flex-direction: column;



}

  }



}