.submit-btn {
    margin-top: 10px;
    --button-default-height: 50px;
--button-default-font-size: 18px;
--button-default-border-radius: 14px;
--button-horizontal-padding: 40px;
--button-raise-level: 4px;
--button-hover-pressure: 2.25;
--transform-speed: 0.15s;
--button-primary-color: #23a425;
--button-primary-color-dark: #e0e0e0;
--button-primary-color-light: #ffffff;
--button-primary-color-hover: #187bd1;
--button-primary-color-active: #166dba;
--button-primary-border: none;
display: absolute;
justify-content: center;
}

.login-bg {
background-color: f0f4f8;
font-family: "Raleway", sans-serif;
padding-top: 100px;
padding-bottom: 255px;

}

