.home-bg {
  /* background-color: beige; */
  background-color: #f0f4f8;
  margin: 0;
  font-family: 'Raleway', sans-serif;;
}

.logo-link {
  cursor: pointer;
}


.header {
  padding-top: 20px;
}

.home-bio {
  margin-bottom: 20px;
  line-height: 1.6;
}

.home-flags {
  width: 84%;
  padding: 1px;
  margin-top: 12px;
  padding-bottom: 18px;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.services, .directions {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: bold;
}



.home-flags:hover {
  -webkit-transform:scale(1.3);
  transform:scale(1.3);

}
.home-logos {
  width: 70%;
  margin-top: 12px;
 
}

.home-logos{
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.home-logos:hover{
  -webkit-transform:scale(1.3);
  transform:scale(1.3);
}


.home-companies {
  padding-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.2);
}

.check-cashing {
  width: 100%;
}

.home-carousel {
  margin-top: 40px;
}

.map {
  padding-bottom: 40px;
  width: 100%;
  position: relative;
}

.maxi {
  margin-left: 100px;
}


.rates-btn {
  --button-default-height: 60px;
  --button-default-font-size: 15px;
  --button-default-border-radius: 12px;
  --button-horizontal-padding: 20px;
  --button-raise-level: 5px;
  --button-hover-pressure: 3;
  --transform-speed: 0.175s;
  --button-primary-color: rgb(103, 10, 10);
  --button-primary-color-dark: #ffffff;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #000000;
  --button-primary-color-active: #000000;
  --button-primary-border: 6px solid transparent;
  --button-secondary-color: #f2f6f9;
}
.home-rates-btn {
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.scroll-up {
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #001f3f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}

.scroll-up:hover {
  opacity: 0.8;
}

.scroll-up-icon {
  width: 24px;
  height: 24px;
  color: #fff;
}

/* ---------------------------------------------------------------- */

/* MEDIA QUERIES */

@media (max-width: 768px) {
  .home-flags {
    width: 75%;
  }

  .home-logos {
    width: 85%;
  }

  .home-bio {
    font-size: 0.9rem;
    padding: 0 10px;
  }

  .map {
    width: 95%;
    padding-bottom: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 480px) {
  .header {
    padding-top: 40px;
  }

  .home-logos {
    width: 80%;
    padding: 10px;
  }

  .home-bio {
    font-size: 0.8rem;
    padding: 0 20px;
  }
}
