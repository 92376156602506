
.rates-bg {
    background-color: f0f4f8;
    margin: 0;
    font-family: 'Raleway', sans-serif;
}

.rates-title {
    font-size: 2rem;
    font-weight: bolder;
    text-decoration: underline;
}

.date {
    padding-top: 10px;
}
.date-txt {
    font-weight: bolder;
}

.flags {
    width: 40%;
}

.logo-header {
    font-size: 1.5rem;
}

.company-logo {

    margin-bottom: 10px;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.company-logo:hover {
    -webkit-transform:scale(1.3);
transform:scale(1.3);
}

.rates-header {

    padding-top:5px;
    text-decoration: underline;
}

.currency {
    margin-left: 25px;
}






.cards {
 margin-left: 50px;
 margin-right: 50px;
 margin-top: 60px;
 padding-bottom: 30px;
}