.contact-form {
  background-color: #f0f4f8;
  font-family: "Raleway", sans-serif;
}

.form {
  margin-top: 100px;
  margin-bottom: 208px;
}

.send-btn {

  --button-default-height: 50px;
  --button-default-font-size: 15px;
  --button-default-border-radius: 12px;
  --button-horizontal-padding: 35px;
  --button-raise-level: 5px;
  --button-hover-pressure: 3;
  --transform-speed: 0.175s;
  --button-primary-color: rgb(103, 10, 10);
  --button-primary-color-dark: beige;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: rgb(0, 0, 0);
  --button-primary-color-active: #000000;
  --button-primary-border: 6px solid transparent;
  --button-secondary-color: #f2f6f9;
}


@media only screen and (max-width: 1024px) {
  .form {
    width: 90%;
  }
}

/* Media Query for Phones */
@media only screen and (max-width: 480px) {
}
