.carousel {
    margin-bottom: 60px;
}

 @media only screen and (max-width: 768px) {
  .carousel {
    width: 90%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .carousel {
    width: 70%;
  }
}

/* @media only screen and (min-width: 1025px) {
  .carousel {
    width: 50%;
  }
}  */
