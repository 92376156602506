.update-btn {
  --button-default-height: 44px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 4px;
  --button-horizontal-padding: 16px;
  --button-raise-level: 4px;
  --button-hover-pressure: 1;
  --transform-speed: 0.15s;
  --button-primary-color: #3fba36;
  --button-primary-color-dark: #181616;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #1d7923;
  --button-primary-color-active: #166dba;
  --button-primary-border: 5px solid transparent;
  margin-left: 10px;
}

.dashboard-bg {
  background-color: f0f4f8;
  font-family: "Raleway", sans-serif;
}

.time {
  font-weight: bolder;
}

.jokes {
  margin-top: 80px;
}

.reset-btn {
  --button-default-height: 44px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 4px;
  --button-horizontal-padding: 16px;
  --button-raise-level: 4px;
  --button-hover-pressure: 1;
  --transform-speed: 0.15s;
  --button-primary-color: #a30000;
  --button-primary-color-dark: #000000;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #590606;
  --button-primary-color-active: #166dba;
  --button-primary-border: 5px solid transparent;
  margin-left: 10px;
}
